import cx from "classnames";
import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { view } from "ramda";

import { WindowLocation } from "@reach/router";
import { Helmet } from "react-helmet";

import listFactory from "../utils/list-lens-factory";
import { StandardProps } from "../utils/element-type";
import { GQEdges } from "../utils/gq-types";

import DesktopHeader from "../components/DesktopHeader";
import MobileHeader from "../components/MobileHeader";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import EmptyComponent from "../components/EmptyComponent";
import { InstagramProps } from "../components/Instagram";

interface LayoutProps
  extends StandardProps<React.HtmlHTMLAttributes<HTMLDivElement>> {
  location: WindowLocation;
}

const TIMEOUT_CONFIG = { timeout: 100 };

type ImportReturnInstagram = {
  default: React.ElementType<InstagramProps>;
};
const importInstagramBlock = async (): Promise<ImportReturnInstagram> => {
  const component = await import(`../components/Instagram`).then(
    (comp) => comp
  );
  return component;
};

interface Keyword {
  id: string;
  title: string;
}
interface LayoutData {
  site: {
    siteMetadata: {
      meta: {
        keywords: string;
        description: string;
      };
    };
  };
  media: {
    image: string;
  };
  keywords: GQEdges<Keyword>;
}

const lens = listFactory<Keyword>();

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  const [instagramComponent, setInstagramComponent] = React.useState<
    ImportReturnInstagram | undefined
  >(undefined);
  const {
    media: { image },
    site: {
      siteMetadata: {
        meta: { keywords: baseKeywords, description },
      },
    },
    keywords,
  } = useStaticQuery<LayoutData>(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          meta {
            description
            keywords
          }
        }
      }
      media: ghostPost {
        image: feature_image
      }
      keywords: allGhostPost {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  `);

  let formattedKeywords = view(lens, keywords);
  formattedKeywords = baseKeywords
    .split(",")
    .concat((formattedKeywords as Keyword[]).map(({ title }) => title))
    .join(", ");

  React.useEffect(() => {
    async function loadImageComponent({
      didTimeout,
    }: RequestIdleCallbackDeadline) {
      if (didTimeout) {
        window.requestIdleCallback(loadImageComponent, TIMEOUT_CONFIG);
      } else {
        const component = await importInstagramBlock();
        setInstagramComponent(component);
      }
    }
    if ("requestIdleCallback" in window) {
      window.requestIdleCallback(loadImageComponent, TIMEOUT_CONFIG);
    }
  }, []);
  const Instagram =
    (instagramComponent && instagramComponent.default) || EmptyComponent;
  return (
    <>
      <Helmet>
        <meta name="keywords" content={formattedKeywords as string} />
        <meta name="description" content={description} />
        <meta name="image" content={image} />
      </Helmet>
      <div className={"relative"}>
        <DesktopHeader />
        <MobileHeader />
        <main className={cx("relative")}>{children}</main>
        <Instagram />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default Layout;
