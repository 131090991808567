import cx from "classnames";
import * as React from "react";

import { Link } from "gatsby";

import { StandardProps } from "../utils/element-type";
import logoWhite from "../images/logo_white.png";

import Nav from "./Nav";
import OuterContainer from "./OuterContainer";

interface DesktopHeaderProps
  extends StandardProps<React.HtmlHTMLAttributes<HTMLDivElement>> {}

const DesktopHeader: React.FunctionComponent<DesktopHeaderProps> = () => {
  return (
    <header
      className={cx(
        "absolute",
        "top-0",
        "left-0",
        "w-full",
        "z-50",
        "transform",
        "transition-transform",
        "duration-300",
        "ease-in-out",
        "-translate-y-full",
        "sm:translate-y-0",
        "bg-transparent"
      )}
    >
      <OuterContainer>
        <div className={cx("flex", "align-middle", "m-0", "p-0")}>
          <div className={cx("flex-auto", "logo-white")}>
            <Link
              to="/"
              className={cx("inline-block")}
              style={{
                maxWidth: "6.25rem",
              }}
            >
              <img src={logoWhite} alt="Memories by Sylvester Aswin" />
            </Link>
          </div>
          <Nav />
        </div>
      </OuterContainer>
    </header>
  );
};

export default DesktopHeader;
