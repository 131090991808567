import cx from "classnames";
import * as React from "react";

import OuterContainer from "./OuterContainer";
import SocialMedia from "./SocialMedia";

export default () => (
  <footer className={cx("pb-10", "bg-gray-900")}>
    <OuterContainer>
      <SocialMedia className={"pt-4"} />
      <div
        className={cx(
          "pt-4",
          "text-center",
          "font-body",
          "text-white",
          "text-xs"
        )}
      >
        Don't just copy, steal em. But tag me atleast.
      </div>
    </OuterContainer>
  </footer>
);
