import cx from "classnames";
import * as React from "react";
import { Link } from "gatsby";

import { StandardProps } from "../utils/element-type";

import OuterContainer from "./OuterContainer";

type ContactProps = StandardProps<React.HtmlHTMLAttributes<HTMLDivElement>>;

const Contact: React.FunctionComponent<ContactProps> = () => {
  return (
    <div className={cx("bg-teal-200", "py-16")}>
      <OuterContainer>
        <div
          className={cx(
            "flex",
            "items-start",
            "lg:items-center",
            "flex-col",
            "lg:flex-row"
          )}
        >
          <h3
            className={cx(
              "text-3xl",
              "text-black",
              "font-display",
              "lg:mr-24",
              "mb-16",
              "lg:mb-0",
              "flex-grow"
            )}
          >
            I love meeting new people. <br />
            If you want to work on a project with me.
          </h3>
          <div className={cx("flex-grow-0")}>
            <Link
              to="/contact/"
              className={cx(
                "bg-black",
                "text-white",
                "font-bold",
                "text-current",
                "px-24",
                "py-4",
                "transition-colors",
                "duration-300",
                "hover:text-black",
                "hover:bg-white"
              )}
            >
              Hit me up
            </Link>
          </div>
        </div>
      </OuterContainer>
    </div>
  );
};

export default Contact;
