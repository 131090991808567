import cx from "classnames";
import * as React from "react";

import { Link } from "gatsby";

import { StandardProps } from "../utils/element-type";

import SocialMedia from "./SocialMedia";

interface NavProps
  extends StandardProps<React.HtmlHTMLAttributes<HTMLDivElement>> {}

const Nav: React.FunctionComponent<NavProps> = () => {
  return (
    <>
      <nav
        className={cx(
          "m-0",
          "p-0",
          "flex-auto",
          "flex",
          "items-center",
          "justify-end"
        )}
      >
        <ul className={"mr-4"}>
          <li className={cx("relative")}>
            <Link
              to="/"
              className={cx(
                "py-2",
                "px-4",
                "text-white",
                "font-body",
                "text-base",
                "font-bold",
                "transition-colors",
                "duration-300",
                "hover:text-black",
                "hover:bg-white"
              )}
            >
              Home
            </Link>
          </li>
        </ul>
      </nav>
      <SocialMedia />
    </>
  );
};

export default Nav;
