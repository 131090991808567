import cx from "classnames";
import * as React from "react";

import { StandardProps } from "../utils/element-type";

type OuterContainerProps = StandardProps<
  React.HtmlHTMLAttributes<HTMLDivElement>
>;

const OuterContainer: React.FunctionComponent<OuterContainerProps> = ({
  children,
  className,
}) => (
  <div
    className={cx(
      "w-full",
      "py-3",
      "px-4",
      "mr-auto",
      "ml-auto",
      "max-w-screen-sm",
      "md:max-w-screen-md",
      "lg:max-w-screen-lg",
      "xl:max-w-screen-xl",
      className
    )}
  >
    {children}
  </div>
);

export default OuterContainer;
