import cx from "classnames";
import * as React from "react";

import { Link } from "gatsby";

import { StandardProps } from "../utils/element-type";
import { IconMenu } from "react-heroicons-ui";
import logoWhite from "../images/logo_white.png";

import Nav from "./Nav";

interface DesktopHeaderProps
  extends StandardProps<React.HtmlHTMLAttributes<HTMLDivElement>> {}

const DesktopHeader: React.FunctionComponent<DesktopHeaderProps> = () => {
  return (
    <header
      className={cx(
        "absolute",
        "top-0",
        "left-0",
        "w-full",
        "z-50",
        "transform",
        "duration-300",
        "ease-in-out",
        "translate-x-0",
        "sm:-translate-y-full"
      )}
    >
      <div className={cx("w-full", "p-2")}>
        <div
          className={cx(
            "flex",
            "items-center",
            "justify-between",
            "m-0",
            "p-0"
          )}
        >
          <Link
            to="/"
            className={cx("inline-block")}
            style={{
              maxWidth: "4.25rem",
            }}
          >
            <img src={logoWhite} alt="Memories by Sylvester Aswin" />
          </Link>
          <button type="button" className={cx("text-white", "p-4")}>
            <IconMenu className={"fill-current"} />
          </button>
        </div>
      </div>
    </header>
  );
};

export default DesktopHeader;
